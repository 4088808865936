import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = ({ pageContext }) => {
  // Hack to fix build
  pageContext.title = '404'
  pageContext.breadcrumb = {
    location: '/404/',
    crumbs: [
      {
        pathname: '/',
        crumbLabel: 'Home',
      },
      {
        pathname: '/404',
        crumbLabel: '404',
      },
    ],
  }
  return (
    <Layout pageContext={pageContext}>
      <div>
        <h1 className="title is-1">NOT FOUND</h1>
      </div>
    </Layout>
  )
}

export default NotFoundPage
